<template>
  <div class="d-inline">
    <v-btn @click="selectBrand()" icon small>
      <v-icon x-small>mdi-pencil</v-icon>
    </v-btn>

    <SelectBrandDialog ref="brandDialog" @selected="selected" />
  </div>
</template>

<script>
import SelectBrandDialog from '../../brand_profiles/components/SelectBrandDialog.vue'

export default {
  props: {
    order: Object,
  },

  components: {
    SelectBrandDialog
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    selectBrand() {
      this.$refs.brandDialog.dialog = true
    },

    selected(profile) {
      this.$store.dispatch('orders/updateOrderField', {
        field: 'brandProfile',
        order: this.order,
        value: profile.id,
      })
    }
  }
}
</script>