<template>
  <div>
    <v-card 
      v-if="order && !order.brandProfile && !$store.state.brand_profile.status.getting" 
      color="#f4fbfe" 
      class="mb-5" 
      flat
    >
      <v-card-text>
        <div class="mb-5">
          You haven't provided us with any information about your business or assets to use in building your website.
        </div>

        <v-btn
          v-if="!$store.state.brand_profile.profiles.length"
          :to="{ name: 'BrandProfiles' }" 
          class="primary--text" 
          color="white" 
          small
        >
          Create A Brand Profile
        </v-btn>
        
        <v-btn
          v-else
          @click="showDialog()"
          class="primary--text" 
          color="white" 
          small
        >
          Select A Brand Profile
        </v-btn>
      </v-card-text>

      <select-brand-dialog 
        @selected="selected" 
        ref="brandDialog" 
      />
    </v-card>
  </div>
</template>

<script>
import SelectBrandDialog from '../../brand_profiles/components/SelectBrandDialog.vue'

export default {
  props: {
    order: Object,
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    SelectBrandDialog
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    showDialog() {
      this.$refs.brandDialog.dialog = true
    },

    selected(profile) {
      Promise.resolve(
        this.$store.dispatch('orders/updateOrderField', {
          order: this.order,
          value: profile.id,
          field: 'brandProfile'
        })
      )
    }
  }
}
</script>