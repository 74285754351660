<template>
  <div class="mt-5">
    <preloader v-if="finalizing" />
    <div v-if="!finalizing">
      <preloader v-if="status.getting" message="Please wait..." />

      <div v-if="!status.getting && order && $store.getters['orders/haveAccess'](order)">
        <!-- HEADER -->
        <div class="d-flex align-center">
          <div class="d-flex">
            <back-square :url="order.status !== 'completed' ? 'InProgressOrders' : 'CompletedOrders'"/>

            <div>
              <h5 class="ml-1 font-weight-bold title">
                {{ $store.getters['orderform/form'](order.form).name }}

                <v-menu :disabled="!$store.getters['orders/canManage']" offset-y>
                  <template #activator="{ on }">
                    <v-icon v-on="on" :color="getPriorityColor(order.priority || 'low')" small right>mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item @click="updateOrderPriority('high')">
                      <v-list-item-title>High</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateOrderPriority('medium')">
                      <v-list-item-title>Medium</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateOrderPriority('low')">
                      <v-list-item-title>Low</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </h5>
              <div class="grey--text d-flex align-center">
                #{{ order.orderNumber }}

                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn 
                      @click="
                        $copyText(`${$window.origin}${$router.resolve({ name: 'OrderDetails', params: { id: order.id } }).href}`)
                        .then(() => $store.dispatch('showSuccess', 'Copied to clipboard'))
                      " 
                      class="ml-2" 
                      v-on="on"
                      small
                      icon 
                    >
                      <v-icon>mdi-link</v-icon>
                    </v-btn>
                  </template>
                  <span class="caption">Copy order URL</span>
                </v-tooltip>
              </div>
            </div>
          </div>

          <v-spacer></v-spacer>

          <v-btn 
            v-if="order.status == 'completed' && !order.feedback && order.user == user.userid" 
            class="mr-2 text-none ls-0 font-weight-medium"
            @click="$refs.feedbackDialog.dialog = true"
            color="primary" 
            small
            text
          >Leave us a feedback</v-btn>

          <status-badge :order="order" />
        </div>

        <!-- MENU -->

        <div 
          class="d-flex align-center mt-3" 
          style="border-bottom: 1px solid rgba(40,63,151,0.1);"
        >
          <div style="margin-bottom: -1px;">
            <v-tabs
              v-model="tab"
              color="primary"
              slider-color="primary"
              class="request-tabs"
            >
              <v-tab 
                v-for="(t, i) in tabs"
                :key="i" 
                @click="$router.push({ query: { view: i } })"
              >
                <span class="slider-title text-capitalize">{{ t.text }}</span>
              </v-tab>
            </v-tabs>
          </div>

          <v-spacer></v-spacer>

          <div 
            v-if="$store.getters['orders/canManage'] && order.status !== 'completed'" 
            class="d-flex align-center"
          >
            <v-btn
              @click="$store.dispatch('orders/updateOrderField', {
                order,
                field: 'status',
                value: 'paused',
              })"
              v-if="order.status !== 'paused'"
              :loading="status.updatingStatus"
              depressed 
              small
              text
            >
              <v-icon color="info" left>mdi-pause</v-icon> 
              <span class="text-none primary--text" style="letter-spacing: 0 !important;">Pause</span>
            </v-btn>
          </div>
        </div>

        <!-- CONTENT -->
        <v-tabs-items v-model="tab" class="box-shadow mt-10">
          <v-tab-item
            v-for="(t, i) in tabs"
            reverse-transition="fade"
            transition="fade" 
            :key="i"
          >
            <component :is="t.component" :order="order"></component>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <div v-if="!status.getting && !$store.getters['orders/haveAccess'](order)">
      Access denied
    </div>

    <feedback-dialog ref="feedbackDialog" :order="order" />
  </div>
</template>

<script>
import firebase from 'firebase'
import db from '@/firebase/init'
import Brief from './components/Brief'
import Files from './components/Files'
import { mapState, mapActions } from 'vuex'
import Messages from './components/Messages'
import StatusBadge from './components/StatusBadge'
import FeedbackDialog from './components/FeedbackDialog'

export default {
  metaInfo: {
    title: 'Order Details'
  },

  name: 'Request',

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      tab: null,
      checked: false,
      finalizing: false,
      tabs: [
        { text: 'brief', component: 'Brief' },
        { text: 'files', component: 'Files' },
        { text: 'messages', component: 'Messages' },
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.orders.status,
      user: state => state.user.user
    }),

    order: function () {
      return this.$store.getters['orders/order'](this.$route.params.id)
    },
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'order': {
      handler(value) {
        if (Object.keys(value).length && !this.checked)
          this.checkOrder()
      },
      immediate: true
    },
    '$route.query.view': {
      handler(view) {
        this.tab = parseInt(view)
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Brief,
    Files,
    Messages,
    StatusBadge,
    FeedbackDialog,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('orders', [
      'getOrder',
      'updateOrderField',
    ]),

    checkOrder() {
      let user = firebase.auth().currentUser
      this.checked = true

      if (this.order.status == 'pending_payment') {
        this.finalizing = true

        db.collection('customers')
        .doc(user.uid)
        .collection('payments')
        .where('metadata.order', '==', this.order.id)
        .where('status', '==', 'succeeded')
        .get().then((snapshot) => {
          if (snapshot.size) {
            return new Promise((resolve) => {
              db.collection('order_forms')
              .doc(this.order.form)
              .get().then(doc => {
                if (doc.exists) {
                  let form = doc.data()
                  form.id = doc.id
                  form.ref = doc.ref
                  
                  Promise.resolve(
                    this.$store.dispatch('orders/finalizeOrder', {
                      order: this.order,
                      form,
                    })
                  )
                  .then(() => resolve('success'))
                }
              })
            })
          }
        })
        .catch(error => {
          console.log(error.message)
        })
        .finally(() => {
          this.finalizing = false
        })
      }
      else if (
        this.order.status == 'completed' && 
        !this.order.feedback && 
        this.order.user == user.uid
      ) {
        this.$refs.feedbackDialog.dialog = true
      }
    },

    getPriorityColor(priority) {
      let colors = {
        low: 'dark lighten-5',
        high: 'error darken-1',
        medium: 'success darken-1',
      }

      return colors[priority]
    },

     updateOrderPriority(priority) {
      this.updateOrderField({
        value: priority,
        order: this.order,
        field: 'priority',
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getOrder(this.$route.params.id)
  }
}
</script>