<template>
  <v-dialog
    v-model="dialog"
    :max-width="$dialog.content"
  >
    <close-btn @click="closeDialog()" overflow />

    <v-card>
      <v-card-title>
        <h5>Select a project</h5>
      </v-card-title>
      <v-card-text>
        <v-card class="box-shadow" outlined>
          <v-list v-if="projects.length" dense>
            <v-list-item
              v-for="project in projects"
              :key="project.id"
              @click="selected(project)"
            >
              <v-list-item-title>
                {{ project.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      dialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.projects.status,
      projects: state => state.projects.projects,
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    closeDialog() {
      this.dialog = false
    },

    selected(project) {
      this.$emit('selected', project)
      this.closeDialog()
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.status.firstLoad)
      this.$store.dispatch('projects/getProjects')
  }
}
</script>