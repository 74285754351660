<template>
  <v-card v-if="order" flat>
    <div class="text-center mb-1">
      <v-btn 
        @click="getMessages(order)"
        class="rounded-t-0 rounded-b-md" 
        :style="{ marginTop: '-1px' }"
        color="#e9ebf4" 
        :loading="getting"
        outlined 
        x-small
        small 
      >Load More</v-btn>
    </div>

    <v-card-text class="pa-10">
      <!-- MESSAGE -->
      <preloader v-if="getting && !messages.length" />

      <v-alert v-if="!getting && !messages.length" class="mb-0" type="info" border="left" text>
        No message found
      </v-alert>

      <template v-if="messages.length">
        <v-fade-transition tag="div" group>
          <div
            v-for="(message, i) in orderBy(messages, 'created', 'asc')"
            :key="message.id"
          >
            <!-- STATUS TYPE -->
            
            <div
              v-if="message.type === 'status_change'"
              class="d-flex space-between"
              :style="{ paddingLeft: '55px' }"
            >
              <span class="caption">
                <em>{{ message.message }}</em> by <span class="font-weight-bold">{{  $store.getters['users/getFullName'](message.sender) }}</span>
              </span>

              <v-spacer></v-spacer>
              
              <span class="caption grey--text">
                {{ message.created | messageDate }}
              </span>
            </div>
            
            <!-- REVIEW TYPE -->
            
            <div
              v-if="message.type === 'review'"
              class="d-flex space-between"
              :style="{ paddingLeft: '55px' }"
            >
              <span class="caption">
                The client left {{ message.rating === 'okay' ? 'an' : 'a' }} {{ message.rating }} feedback. <em>"{{ message.message || 'The client did not leave a message.' }}"</em>
              </span>

              <v-spacer></v-spacer>
              
              <span class="caption grey--text">
                {{ message.created | messageDate }}
              </span>
            </div>
            
            <!-- NOTE TYPE -->
            
            <v-hover v-slot:default="{ hover }">
              <div
                v-if="message.note && (user.organization || user.role == 'admin')" 
                class="pa-10 lime lighten-5 mb-5"
                style="position: relative"
              >
                <div class="d-flex align-center gap-10">
                  <user-photo
                    :id="message.sender"
                    photoSize="thumb"
                    :size="30"
                    rounded
                  />

                  <div class="font-weight-bold dark--text">
                    {{ $store.getters['users/getFullName'](message.sender) }}
                    <div class="caption mt-n1 grey--text">{{ $store.getters['users/getRole'](message.sender) }}</div>
                  </div>

                  <v-spacer></v-spacer>

                  <span v-if="message.updated" class="caption grey--text">
                    edited {{ message.updated | messageDate }}
                  </span>
                  <span v-else class="caption grey--text">
                    {{ message.created | messageDate }}
                  </span>
                </div>
                
                
                <div class="mt-5">
                  <div class="text-pre-line">
                    <v-sheet color="transparent" class="d-flex align-start" max-width="750">
                      <v-icon class="mr-3 mt-1" small>mdi-pin</v-icon>
                      <div>
                        <div 
                          class="mb-0 dark--text text--lighten-1 message" 
                          v-html="$options.filters.linkify(sanitizeHtml(message.message, $sanitize))"
                        ></div>

                        <div
                          v-if="attached(message.id).length"
                          class="mt-5 d-flex gap-7 flex-wrap"
                        >
                          <file
                            v-for="file in attached(message.id)"
                            :key="file.id"
                            :file="file"
                          />
                        </div>
                      </div>
                    </v-sheet>
                  </div>
                </div>

                <v-fade-transition>
                  <div class="d-flex gap-3" 
                    :style="{ position: 'absolute', bottom: '10px', right: '10px' }" 
                    v-if="message.sender === user.userid && hover"
                  >
                    <v-btn
                      @click="editNote(message)"
                      small
                      icon
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>

                    <v-btn
                      @click="showDeleteDialog(message)"
                      color="error"
                      small
                      icon
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-fade-transition>
              </div>
            </v-hover>
            
            <!-- NORMAL TYPE -->
            
            <v-hover v-slot:default="{ hover }">
              <div 
                v-if="!message.note && !['status_change', 'review'].includes(message.type)"
                class="d-flex gap-15" 
                style="position: relative"
              >
                <user-photo
                  :id="message.sender"
                  photoSize="thumb"
                  :size="40"
                  rounded
                />

                <div class="flex-grow-1">
                  <div class="font-weight-bold dark--text mb-5">
                    {{ $store.getters['users/getFullName'](message.sender) }}
                    <div class="caption mt-n1 grey--text">{{ $store.getters['users/getRole'](message.sender) }}</div>
                  </div>
                  <div class="text-pre-line">
                    <v-sheet max-width="750">
                      <div 
                        class="mb-0 dark--text text--lighten-1 message" 
                        v-html="$options.filters.linkify(sanitizeHtml(message.message, $sanitize))"
                      ></div>

                      <div
                        v-if="attached(message.id).length"
                        class="mt-5 d-flex gap-7 flex-wrap"
                      >
                        <file
                          v-for="file in attached(message.id)"
                          :key="file.id"
                          :file="file"
                        />
                      </div>
                    </v-sheet>
                  </div>
                </div>
                <span class="caption grey--text">
                  {{ message.created | messageDate }}
                </span>

                <v-fade-transition>
                  <v-btn
                    v-if="message.sender == user.userid && hover"
                    @click="showDeleteDialog(message)"
                    color="error"
                    absolute
                    bottom
                    right
                    small
                    icon
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </v-fade-transition>
              </div>
            </v-hover>

            <v-divider 
              v-if="messages.length !== (i + 1)" 
              :style="{ width: 'calc(100% - 55px)' }"
              class="my-5 ml-auto"
            ></v-divider>
          </div>
        </v-fade-transition>
      </template>
    </v-card-text>
    <v-divider></v-divider>
    
    <!-- MESSAGE FIELD -->
    
    <v-card flat>
      <v-file-input
        v-model="attachments"
        @change="filesSelected($event)"
        ref="attachments"
        class="d-none"
        multiple
      ></v-file-input>

      <v-fade-transition v-if="files.length" class="v-card__text d-flex flex-wrap align-center gap-12 pa-5 pb-0" group>
        <file-block
          v-for="(file, i) in files"
          @remove="removeAttachment(file)"
          :file="file"
          :key="i"
        />
      </v-fade-transition>

      <div v-if="$store.state.file_upload.uploads.length" class="pa-5">
        <upload-progress />
      </div>

      <v-card-text class="pa-5 gap-12 d-flex">
        <div class="d-flex flex-column">
          <v-btn 
            @click="$refs.attachments.$refs.input.click()" 
            :style="{ marginTop: '70px' }"
            x-small 
            icon
          >
            <v-icon small>mdi-paperclip</v-icon>
          </v-btn>
          
          <v-btn 
            @click="showResponseDialog()"
            v-if="user && (user.organization || user.role == 'admin') && type == 0"
            class="mt-2"
            x-small 
            icon
          >
            <v-icon small>mdi-message-text</v-icon>
          </v-btn>
        </div>

        <div class="flex-grow-1">
          <v-tabs
            v-model="type"
            slider-color="primary"
            color="primary"
          >
            <v-tab class="text-none ls-0 caption">
              Send Message
            </v-tab>
            <v-tab v-if="user && user.organization || user.role == 'admin'" class="text-none ls-0 caption">
              Add A Note
            </v-tab>
          </v-tabs>
          <v-divider class="mb-5"></v-divider>
          
          <v-tabs-items v-model="type" vertical>
            <v-tab-item>
              <v-form ref="messageForm" class="flex-grow-1 messageForm">
                <MessageField 
                  v-model="message"
                  ref="messageField" 
                  :companyProfile="order.company_profile"
                />

                <v-btn 
                  @click="validateForm()"
                  class="primary--text"
                  :disabled="!message"
                  :loading="sending"
                  color="white"
                  outlined
                  small
                >
                  {{ order && order.status == 'completed' ? 'Reopen Request and Send Message' : 'Send' }}
                  <v-icon small right>mdi-send</v-icon>
                </v-btn>
              </v-form>
            </v-tab-item>
            <v-tab-item>
              <v-form ref="noteForm" class="flex-grow-1">
                <MessageField 
                  note
                  v-model="note"
                  ref="noteField" 
                  :companyProfile="order.company_profile"
                />

                <v-btn
                  v-if="!toEdit" 
                  @click="validateForm(true)"
                  class="primary--text"
                  :loading="sending"
                  :disabled="!note"
                  color="white"
                  outlined
                  small
                >Post</v-btn>

                <div v-else-if="toEdit" class="d-flex gap-5 align-center">
                  <v-btn
                    @click="validateForm(true)"
                    class="primary--text"
                    :loading="sending"
                    :disabled="!note"
                    color="white"
                    outlined
                    small
                  >Update Note</v-btn>

                  <v-btn @click="cancelEdit()" text small>Cancel</v-btn>
                </div>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-card-text>
    </v-card>

    <!-- CONFIRM DELETE -->
    <confirm-delete
      message="Are you sure you want to delete this message?"
      @confirmed="deleteConfirmed()"
      @cancel="closeDeleteDialog()"
      :show="showDeleteMessage"
      :deleting="deleting"
    />

    <!-- CANNED RESPONSES -->
    <CannedResponses ref="cannedResponses" @selected="cannedResponseSelected" />

    <!-- IMAGE DIALOG -->
    <v-dialog
      content-class="img-preview"
      v-model="imgDialog"
      scrollable
      tile
      flat
    >
      <div class="d-flex full-width full-height justify-center align-center">
        <img :src="imgSrc" class="mx-auto" />
      </div>
    </v-dialog>
  </v-card>
</template>

<script>
import rules from '@/rules'
import firebase from 'firebase'
import Mustache from 'mustache'
import { mapState } from 'vuex'
import sanitizeHtml from 'sanitize-html'
import { orderBy, uniq, pick } from 'lodash'

import File from './FileBlock'
import FileBlock from './FileBlockSimple'
import MessageField from '@/components/MessageField'
import CannedResponses from '../../canned_responses/components/SelectCannedResponseDialog.vue'

export default {
  props: {
    order: Object,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      pick,
      rules,
      orderBy,
      files: [],
      imgSrc: '',
      type: null,
      note: null,
      chat: false,
      messages: [],
      sanitizeHtml,
      toEdit: null,
      message: null,
      getting: false,
      sending: false,
      attachments: [],
      toDelete: false,
      deleting: false,
      imgDialog: false,
      lastVisible: null,
      messageAttachments: [],
      showDeleteMessage: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      cannedResponses: state => state.request.cannedResponses,
    }),
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'order': {
      handler(order) {
        if (Object.keys(order).length) {
          this.messages = []
          this.checkMessages(order)
          this.getAttachments(order)
        } 
      },
      immediate: true
    },

    '$route.query.v': function (v) {
      if (v == '2') this.scrollDown()
    },
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    File,
    FileBlock,
    MessageField,
    CannedResponses,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    // ...mapActions('request', [
    //   'updateRequestField',
    // ]),

    /*------------------------------------------------------------------------------
     * CREATE MESSAGE
     *----------------------------------------------------------------------------*/
    async validateForm(note = false) {
      let valid = note ? this.$refs.noteForm.validate() : this.$refs.messageForm.validate()
      let messageCopy = note ? this.note : this.message
      var message = null

      if (this.toEdit) {
        message = this.messages.find(m => m.id === this.toEdit)
      }

      if (valid) {
        this.sending = true

        let data = {
          note,
          sender: this.user.userid,
          message: note ? this.note : this.message,
          created: this.toEdit ? message.created : firebase.firestore.Timestamp.now(),
        }

        if (this.toEdit) {
          data.updated = firebase.firestore.Timestamp.now()
        }

        // if (this.order.status === 'completed') {
        //  await this.updateRequestField({
        //     silent: true,
        //     field: 'status',
        //     value: 'in_progress',
        //     order: this.order,
        //   })
        // }

        var docRef = this.toEdit ? this.order.ref.collection('messages').doc(this.toEdit) : this.order.ref.collection('messages').doc()

        docRef
          .set(data)
          .then(() => {
            this.sending = false
            this.message = null
            this.note = null
            
            if (!note) {
              this.$refs.messageField.editorData = ''
            }
            else if (note) {
              this.$refs.noteField.editorData = ''
            }

            if (this.toEdit) {
              data.id = docRef.id
              data.ref = docRef
              this.$set(this.messages, this.messages.indexOf(message), data)
              this.cancelEdit()
            }

            this.$store.dispatch('showSuccess', 'Message sent')
            this.createNotifications({ messageCopy, messageId: docRef.id, note })

            // SEND AN EMAIL TO CLIENT 
            // WHEN THERES A NEW MESSAGE
            if (this.order.user !== data.sender && !note) {
              this.$store.dispatch('email/sendMail', {
                to: this.$store.getters['users/user'](this.order.user).email,
                subject: `New message from your order #${this.order.orderNumber}`,
                message: `
                  Hi ${this.$store.getters['users/user'](this.order.user).fullName},<br/>
                  <br/>
                  ${this.user.fullName} sent a message from your order #${this.order.orderNumber}.<br/>
                  Click <a href="${window.origin}${this.$router.resolve({ name: 'Order', params: { id: this.order.id }, query: { v: 2 } }).href}">here</a> or follow the link below to read the message.<br>
                  ${window.origin}${this.$router.resolve({ name: 'Order', params: { id: this.order.id }, query: { v: 2 } }).href}
                `
              })
            }

            // SEND AN EMAIL TO THE ASSIGNEE
            // ONCE A CLIENT SENT A MESSAGE
            if (this.order.user === data.sender) {
              this.$store.dispatch('email/sendMail', {
                to: this.$store.getters['users/user'](this.order.assignee).email,
                subject: `Client sent a message for the order #${this.order.orderNumber}`,
                message: `
                  Hi ${this.$store.getters['users/user'](this.order.assignee).fullName},<br/>
                  <br/>
                  Client <strong>${this.user.fullName}</strong> left a message from the order <strong>#${this.order.orderNumber}</strong> that was assigned to you.<br/>
                  Click <a href="${window.origin}${this.$router.resolve({ name: 'Order', params: { id: this.order.id }, query: { v: 2 } }).href}">here</a> or follow the link below to read the message.<br>
                  ${window.origin}${this.$router.resolve({ name: 'Order', params: { id: this.order.id }, query: { v: 2 } }).href}
                `
              })
            }

            if (this.files.length) this.uploadFiles(docRef.id)
          })
          .catch(error => {
            this.sending = false
            console.log(error.message)
            this.$store.dispatch('showError', error.message)
          })
      }
      else {
        this.$store.dispatch('showError', note ? 'Note field is required' : 'Message field is required')
      }
    },

    /*------------------------------------------------------------------------------
     * UPLOAD FILES
     *----------------------------------------------------------------------------*/
    uploadFiles(id) {
      let promises = []

      if (this.files.length) {
        this.files.forEach(file => {
          promises.push(this.$store.dispatch('file_upload/uploadFile', {
            folder: 'orders',
            file,
          }))
        })

        Promise.all(promises)
        .then((response) => {
          response.forEach(file => {
            file.created = firebase.firestore.Timestamp.now()
            file.message = id

            this.order.ref
            .collection('attachments')
            .add(file)
            .catch(error => {
              console.log(error.message)
            })
          })

          this.files = []
        })
      }
    },

    /*------------------------------------------------------------------------------
     * FILES SELECTED
     *----------------------------------------------------------------------------*/
    filesSelected(files) {
      if (files) {
        files.forEach(file => {
          if (!this.files.find(f => f == file))
            this.files.push(file)
        })
      }
    },

    /*------------------------------------------------------------------------------
     * REMOVE ATTACHMENT
     *----------------------------------------------------------------------------*/
    removeAttachment(file) {
      this.files.splice(this.files.indexOf(file), 1)
    },

    /*------------------------------------------------------------------------------
     * CREATE NOTIFICATIONS
     *----------------------------------------------------------------------------*/
    createNotifications({ messageCopy, messageId, note }) {
      let recipients = []
      let user = firebase.auth().currentUser

      recipients.push(this.order.user)
      if (this.order.assignee) recipients.push(this.order.assignee)
      if (this.order.followers) recipients = recipients.concat(this.order.followers)
      recipients = recipients.filter(recipient => recipient !== user.uid)
      recipients = uniq(recipients)

      // CHECK FOR MENTIONS
      var msg = document.createElement('div')
      msg.innerHTML = messageCopy
      var mentions = msg.getElementsByClassName('mention')
      
      if (mentions.length) {
        for (var mention of mentions) {
          let id = mention.getAttribute('data-user-id')
          
          this.$store.dispatch('notifications/createNotification', {
            messageId,
            viewed: false,
            recipient: id,
            version: '2.0',
            sender: user.uid,
            urlParams: [{ view: 2 }],
            origin: this.order.id,
            type: note ? 'order_note_mentioned' : 'order_message_mentioned',
          })

          // REMOVE FROM RECIPIENTS
          recipients.splice(recipients.indexOf(id), 1)
        }
      }


      // CREATE NOTIFICATIONS
      if (recipients.length) {
        recipients.forEach(recipient => {
          if (!note || (note && recipient !== this.order.user)) {
            this.$store.dispatch('notifications/createNotification', {
              recipient,
              messageId,
              viewed: false,
              version: '2.0',
              sender: user.uid,
              urlParams: [{ view: 2 }],
              origin: this.order.id,
              type: note ? 'order_note' : 'order_message',
            })
          }
        })
      }
    },

    showDeleteDialog(message) {
      this.toDelete = message
      this.showDeleteMessage = true
    },

    closeDeleteDialog() {
      this.toDelete = null
      this.showDeleteMessage = false
    },

    /*------------------------------------------------------------------------------
     * DELETE MESSAGE
     *----------------------------------------------------------------------------*/
    deleteConfirmed() {
      this.deleting = true

      this.toDelete.ref
      .delete()
      .then(() => {
        this.deleting = false
        this.closeDeleteDialog()
        this.$store.dispatch('showSuccess', 'Message deleted')
      })
      .catch(error => {
        this.deleting = false
        console.log(error.message)
        this.$store.dispatch('showError', error.message)
      })
    },

    /*------------------------------------------------------------------------------
     * MANUALLY GET MESSAGES
     *----------------------------------------------------------------------------*/
    async getMessages(order) {
      this.getting = true

      let query = order.ref
      .collection('messages')
      .orderBy('created', 'desc')
      
      if (this.lastVisible) query = query.startAfter(this.lastVisible)
      
      await query.limit(5).get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let message = doc.data()
            message.id = doc.id
            message.ref = doc.ref
            if (!this.messages.find(m => m.id == message.id))
              this.messages.push(message)
          })

          this.lastVisible = snapshot.docs[snapshot.docs.length - 1]
          this.selectImages()
        }
        else {
          this.$store.dispatch('showError', 'No more messages found.')
        }

        this.getting = false
      })
      .catch(error => {
        this.getting = false
        console.log(error.message)
      })
    },

    /*------------------------------------------------------------------------------
     * CHECK MESSAGES
     *----------------------------------------------------------------------------*/
    checkMessages(order) {
      this.$watch('user', function (val) {
        if (val) {
          let task = order.ref
          .collection('messages')
          
          if (!val.organization && val.role !== 'admin')
            task = task.where('note', 'in', [false])

          task.orderBy('created', 'desc')
          .limit(5)
          .onSnapshot((snapshot) => {
            if (snapshot.size) {
              snapshot.docChanges().forEach((change) => {
                if (change.type === 'added') {
                  let message = change.doc.data()
                  message.id = change.doc.id
                  message.ref = change.doc.ref
                  
                  if (!this.messages.find(m => m.id == message.id)) {
                    this.messages.push(message)
                  }
                }
                else if (change.type === 'removed') {
                  let message = this.messages.find(m => m.id == change.doc.id)
                  if (message) this.messages.splice(this.messages.indexOf(message), 1)
                }
              })

              this.lastVisible = snapshot.docs[snapshot.docs.length - 1]
              this.scrollDown()

              // CLICK IMAGE
              this.selectImages()
            }
          })
        }
      }, { immediate: true } )
    },

    /*------------------------------------------------------------------------------
     * GET ATTACHMENTS
     *----------------------------------------------------------------------------*/
    getAttachments(order) {
      order.ref
      .collection('attachments')
      .orderBy('message')
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let file = doc.data()
            file.ref = doc.ref
            file.id = doc.id

            if (!this.messageAttachments.find(f => f.id == file.id))
              this.messageAttachments.push(file)
          })
        }
      })
    },

    /*------------------------------------------------------------------------------
     * ATTACHED
     *----------------------------------------------------------------------------*/
    attached(id) {
      return this.messageAttachments.filter(attachment => {
        return attachment.message == id
      })
    },

    /*------------------------------------------------------------------------------
     * SCROLL DOWN
     *----------------------------------------------------------------------------*/
    scrollDown() {
      setTimeout(() => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
      }, 1000)
    },

    showResponseDialog() {
      this.$refs.cannedResponses.showDialog()
    },

    cannedResponseSelected(response) {
      var customer = this.$store.state.users.users.find(u => u.userid == this.order.user)
      var rendered = Mustache.render(response.message, pick(customer, ['fullName', 'email'])) 

      this.$refs.messageField.editorData = rendered
    },

    selectImages() {
      setTimeout(() => {
        document.querySelectorAll('.message img')
          .forEach( (occurence) => {
            occurence.addEventListener('click', (e) => {
              this.imgSrc = e.target.src
              this.imgDialog = true
            })
          })
      }, 1000)
    },

    // EDIT NOTE
    editNote(note) {
      this.type = 1
      this.toEdit = note.id

      var checkNoteRef = setInterval(() => {
        if (this.$refs.noteField) {
          this.$refs.noteField.editorData = note.message
          clearInterval(checkNoteRef)
          this.scrollDown()
        }
      }, 300)
    },

    // CANCEL EDIT
    cancelEdit() {
      this.$refs.noteField.editorData = ''
      this.toEdit = null
    }
  },


  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.chat = true
  }
}
</script>

<style lang="scss">
.message-field {
  textarea {
    max-width: 750px;
  }
}

span.ck-file-dialog-button {
  display: none;
}

.mention {
  background: hsla(220, 100%, 83%, 0.4);
  color: hsl(221, 77%, 42%);
}

pre {
  background: hsla(0,0%,78%,.3);
  border: 1px solid #c4c4c4;
  padding: 10px;
}

.media {
  & > div {
    width: 100%;
    height: 100%;
    
    & > div {
      position: relative; 
      padding-bottom: 100%; 
      height: 0; 
      padding-bottom: 56.2493%;
    
      iframe {
        position: absolute; 
        width: 100%; 
        height: 100%; 
        top: 0; 
        left: 0;
      }
    }
  }
}

.message {
  img {
    width: auto;
    max-width: 100%;
    cursor: zoom-in;
  }
}

.v-dialog {
  &.img-preview {
    width: auto !important;
    box-shadow: none;

    div {
      width: auto !important;
    }
  }
}
</style>