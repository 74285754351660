var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[(_vm.finalizing)?_c('preloader'):_vm._e(),(!_vm.finalizing)?_c('div',[(_vm.status.getting)?_c('preloader',{attrs:{"message":"Please wait..."}}):_vm._e(),(!_vm.status.getting && _vm.order && _vm.$store.getters['orders/haveAccess'](_vm.order))?_c('div',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex"},[_c('back-square',{attrs:{"url":_vm.order.status !== 'completed' ? 'InProgressOrders' : 'CompletedOrders'}}),_c('div',[_c('h5',{staticClass:"ml-1 font-weight-bold title"},[_vm._v(" "+_vm._s(_vm.$store.getters['orderform/form'](_vm.order.form).name)+" "),_c('v-menu',{attrs:{"disabled":!_vm.$store.getters['orders/canManage'],"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.getPriorityColor(_vm.order.priority || 'low'),"small":"","right":""}},on),[_vm._v("mdi-checkbox-blank-circle")])]}}],null,false,4150754674)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.updateOrderPriority('high')}}},[_c('v-list-item-title',[_vm._v("High")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.updateOrderPriority('medium')}}},[_c('v-list-item-title',[_vm._v("Medium")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.updateOrderPriority('low')}}},[_c('v-list-item-title',[_vm._v("Low")])],1)],1)],1)],1),_c('div',{staticClass:"grey--text d-flex align-center"},[_vm._v(" #"+_vm._s(_vm.order.orderNumber)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","icon":""},on:{"click":function($event){_vm.$copyText(("" + (_vm.$window.origin) + (_vm.$router.resolve({ name: 'OrderDetails', params: { id: _vm.order.id } }).href)))
                      .then(function () { return _vm.$store.dispatch('showSuccess', 'Copied to clipboard'); })}}},on),[_c('v-icon',[_vm._v("mdi-link")])],1)]}}],null,false,1735596750)},[_c('span',{staticClass:"caption"},[_vm._v("Copy order URL")])])],1)])],1),_c('v-spacer'),(_vm.order.status == 'completed' && !_vm.order.feedback && _vm.order.user == _vm.user.userid)?_c('v-btn',{staticClass:"mr-2 text-none ls-0 font-weight-medium",attrs:{"color":"primary","small":"","text":""},on:{"click":function($event){_vm.$refs.feedbackDialog.dialog = true}}},[_vm._v("Leave us a feedback")]):_vm._e(),_c('status-badge',{attrs:{"order":_vm.order}})],1),_c('div',{staticClass:"d-flex align-center mt-3",staticStyle:{"border-bottom":"1px solid rgba(40,63,151,0.1)"}},[_c('div',{staticStyle:{"margin-bottom":"-1px"}},[_c('v-tabs',{staticClass:"request-tabs",attrs:{"color":"primary","slider-color":"primary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(t,i){return _c('v-tab',{key:i,on:{"click":function($event){return _vm.$router.push({ query: { view: i } })}}},[_c('span',{staticClass:"slider-title text-capitalize"},[_vm._v(_vm._s(t.text))])])}),1)],1),_c('v-spacer'),(_vm.$store.getters['orders/canManage'] && _vm.order.status !== 'completed')?_c('div',{staticClass:"d-flex align-center"},[(_vm.order.status !== 'paused')?_c('v-btn',{attrs:{"loading":_vm.status.updatingStatus,"depressed":"","small":"","text":""},on:{"click":function($event){return _vm.$store.dispatch('orders/updateOrderField', {
              order: _vm.order,
              field: 'status',
              value: 'paused',
            })}}},[_c('v-icon',{attrs:{"color":"info","left":""}},[_vm._v("mdi-pause")]),_c('span',{staticClass:"text-none primary--text",staticStyle:{"letter-spacing":"0 !important"}},[_vm._v("Pause")])],1):_vm._e()],1):_vm._e()],1),_c('v-tabs-items',{staticClass:"box-shadow mt-10",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(t,i){return _c('v-tab-item',{key:i,attrs:{"reverse-transition":"fade","transition":"fade"}},[_c(t.component,{tag:"component",attrs:{"order":_vm.order}})],1)}),1)],1):_vm._e()],1):_vm._e(),(!_vm.status.getting && !_vm.$store.getters['orders/haveAccess'](_vm.order))?_c('div',[_vm._v(" Access denied ")]):_vm._e(),_c('feedback-dialog',{ref:"feedbackDialog",attrs:{"order":_vm.order}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }