<template>
  <v-card v-if="order" outlined>
    <v-card-text class="pa-10">
      <v-alert
        v-if="order.status == 'pending_payment'"
        border="left"
        type="info"
        text
      >
        Complete your order so we can start working on your order.

        <v-btn
          @click="$store.dispatch('products/payOnce', order)" 
          :loading="$store.state.products.status.paying"
          class="primary--text"
          color="white" 
          absolute
          right
          small
        >
          Complete Order
        </v-btn>
      </v-alert>

      <v-row class="mb-3" no-gutters>
        <!-- PROJECT MANAGER -->
        <v-col cols="2">
          <div :class="labelClass">
            Project Manager
          </div>
          
          <Assignee :order="order" />
        </v-col>

        <!-- BUILD TYPE -->
        <v-col cols="2">
          <div :class="labelClass">
            Build Type:
          </div>
          <div>
            {{ $store.getters['order_types/type']($store.getters['orderform/form'](order.form).orderType).name }}
          </div>
        </v-col>

        <!-- ORDER CREATED -->
        <v-col cols="2">
          <div :class="labelClass">
            Order Created:
          </div>
          <div v-if="order.created">
            {{ order.created | formatDate }}
          </div>
        </v-col>
        
        <!-- LAST UPDATED -->
        <v-col cols="2">
          <div :class="labelClass">
            Last Updated:
          </div>
          <div v-if="order.created">
            {{ order.created | formatDate }}
          </div>
        </v-col>

        <!-- ESTIMATED DELIVERY -->
        <v-col cols="2">
          <div :class="labelClass">
            Estimated Delivery:
          </div>
          <div>
            <div v-if="order.dueDate" class="d-inline">
              <span>
                {{
                  order.dueDate
                    ? `${$options.filters.formatDate(order.dueDate)}`
                    : "TBA"
                }}
              </span>
              <span
                v-if="
                  order.dueDate &&
                  moment(order.dueDate.toDate()).diff(moment(), 'hours') >
                    -24 &&
                  moment(order.dueDate.toDate()).diff(moment(), 'hours') < 0
                "
                class="info--text"
              >
                today
              </span>
              <span
                v-else-if="order.dueDate"
                class="ml-1"
                :class="
                  $overdue(order) ? 'error--text' : 'grey--text lighten-2'
                "
              >
                {{ moment(order.dueDate.toDate()).fromNow() }}
              </span>
            </div>
            <span v-else>TBA</span>

            <v-menu 
              v-if="$store.getters['orders/canManage']" 
              offset-y
              left
            >
              <template #activator="{ on }">
                <v-btn 
                  class="ml-1"
                  v-on="on"
                  small
                  icon
                >
                  <v-icon x-small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-date-picker
                  v-model="date"
                  @change="setDeliveryDate"
                  class="mt-4"
                ></v-date-picker>
              </v-card>
            </v-menu>
          </div>
        </v-col>
        
        <!-- FOLLOWERS -->
        <v-col cols="2" v-if="$store.getters['orders/canManage']">
          <div :class="labelClass">
            Followers:
          </div>
          <div>
            <Followers :order="order" />
          </div>
        </v-col>
      </v-row>

      <v-row class="mb-5" no-gutters>
        <v-col cols="2"></v-col>

        <v-col v-if="!$store.getters['orders/isOwner'](order)" cols="2">
          <template>
            <div :class="labelClass">Client :</div>
            <ClientDropdown :client-id="order.user" />
          </template>
        </v-col>
        
        <v-col cols="2">
          <div :class="labelClass">Company Profile :</div>
          <a v-if="order.brandProfile" :href="$router.resolve({ name: 'BrandProfile', params: { id: order.brandProfile } }).href" target="_blank">
            {{ $store.getters['brand_profile/profile'](order.brandProfile).name }}
          </a>
          <span v-else>None</span>

          <EditBrandProfileButton 
            v-if="$store.getters['orders/isOwner'](order)" 
            :order="order"
            class="ml-1" 
          />
        </v-col>
        
        <v-col cols="2">
          <div :class="labelClass">Project :</div>
          <a v-if="order.project" :href="$router.resolve({ name: 'Project', params: { id: order.project } }).href" target="_blank">
            {{ $store.getters['projects/project'](order.project).name }}
          </a>
          <span v-else>None</span>

          <EditOrderProjectButton 
            v-if="$store.getters['orders/isOwner'](order)" 
            :order="order"
            class="ml-1" 
          />
        </v-col>
        
        <v-col cols="2">
          <div :class="labelClass">{{ order.productLabel  }} :</div>
          {{ $store.getters['products/price'](order.price).description }}
        </v-col>
      </v-row>

      <v-divider class="my-5"></v-divider>

      <v-sheet max-width="750" color="">
        <brand-profile v-if="$store.getters['orders/isOwner'](order)" :order="order" />
        <select-project v-if="$store.getters['orders/isOwner'](order)" :order="order" />      

        <v-row>
          <v-col
            v-for="(entry, i) in orderBy(order.entries.filter(o => o.label), 'order', 'asc')"
            :key="i"
            cols="6"
          >
            <template v-if="entry.type == 'header'">
              <div class="mb-6">
                <h5 class="font-weight-bold" style="font-size: 18px">{{ entry.label }}</h5>
                <div class="subtle-text" v-if="entry.description" style="font-size: 16px">{{ entry.description }}</div>
              </div>
            </template>
            <template v-else>
              <div>
                <div class="mb-1">
                  {{ entry.label }}
                  <div v-if="entry.description" class="font-weight-medium">{{ entry.description }}</div>
                </div>
                <div v-if="entry.value && !Array.isArray(entry.value)" class="grey--text">{{ entry.value }}</div>
                <div v-else-if="Array.isArray(entry.value)" class="text-pre-wrap subtitle-1">{{ entry.value.join('\n') }}</div>

                <div 
                  v-if="entry.type == 'attachment'"
                  class="d-flex gap-7"
                >
                  <div 
                    v-if="$store.getters['request/getAttachments'](entry.order).length"
                    class="d-flex gap-7 mt-2"
                  >
                    <file-block
                      v-for="file in $store.getters['request/getAttachments'](entry.order)"
                      :key="file.id"
                      :file="file"
                    />
                  </div>
                  <div v-else>
                    No attached file
                  </div>
                </div>
              </div>
            </template>
          </v-col>
        </v-row>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
import firebase from 'firebase'
import { mapActions } from 'vuex'

import Assignee from './Assignee'
import FileBlock from './FileBlock'
import Followers from './Followers'
import orderBy from 'lodash/orderBy'
import BrandProfile from './BrandProfile'
import SelectProject from './SelectProject'
import ClientDropdown from '@/components/ClientDropdown'
import EditBrandProfileButton from './EditBrandProfileButton'
import EditOrderProjectButton from './EditOrderProjectButton'

export default {
  props: {
    order: Object  
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      moment,
      orderBy,
      date: null,
      labelClass: 'font-weight-bold subtle-text caption text-uppercase mb-2'
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'order': {
      handler(order) {
        if (Object.keys(order).length && order.dueDate)  {
          this.date = moment(order.dueDate.toDate()).format('YYYY-MM-DD')
        }
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Assignee,
    FileBlock,
    Followers,
    BrandProfile,
    SelectProject,
    ClientDropdown,
    EditBrandProfileButton,
    EditOrderProjectButton,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('orders', [
      'updateOrderField'
    ]),

    setDeliveryDate(date) {
      let newDate = firebase.firestore.Timestamp.fromDate(new Date(`${date} 00:00:00`))

      this.updateOrderField({
        order: this.order,
        field: 'dueDate',
        value: newDate
      })
    }
  },
}
</script>