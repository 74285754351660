<template>
  <div class="d-inline">
    <v-btn @click="selectBrand()" icon small>
      <v-icon x-small>mdi-pencil</v-icon>
    </v-btn>

    <SelectProjectDialog ref="projectDialog" @selected="selected" />
  </div>
</template>

<script>
import SelectProjectDialog from '../../project/components/SelectProjectDialog.vue'

export default {
  props: {
    order: Object,
  },

  components: {
    SelectProjectDialog
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    selectBrand() {
      this.$refs.projectDialog.dialog = true
    },

    selected(project) {
      this.$store.dispatch('orders/updateOrderField', {
        field: 'project',
        order: this.order,
        value: project.id,
      })
    }
  }
}
</script>