<template>
  <div v-if="order && user" class="d-inline">
    <v-menu :disabled="!$store.getters['orders/canChangeStatus'](order)" offset-y>
      <template #activator="{ on }">
        <v-chip 
          :color="`${getColor(order.status)} lighten-5`" 
          @click.prevent v-on="on" 
          small
          label
        >
          <v-icon :color="getColor(order.status)" left x-small>
            mdi-circle
          </v-icon>
          <span :class="`${getColor(order.status)}--text`">
            {{ user && user.role === 'client' && order.status == 'escalated' ? 'in_progress' : order.status | startCase }}
          </span>
        </v-chip>
      </template>
      <v-list dense>
        <v-list-item 
          v-for="(status, i) in Object.keys(omit(statuses, ['pending_payment']))" 
          @click="selected(status)"
          :key="i"
        >
          <v-list-item-title class="caption">{{ status | startCase }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    
  </div>
</template>

<script>
import firebase from 'firebase'
import { startCase, omit } from 'lodash'

export default {
  props: {
    order: Object
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      omit,
      statuses: {
        collecting_content: 'primary',
        in_progress: 'primary',
        checking_quality: 'primary',
        reviewing_with_client: 'primary',
        completed: 'success',
        escalated: 'error',
        pending_payment: 'warning',
      },
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    user: function () {
      return this.$store.state.user.user
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    selected(status) {
      Promise.resolve(
        this.$store.dispatch('orders/updateOrderField', {
          order: this.order,
          field: 'status',
          value: status
        })
      )
      .then(() => {
          let user = firebase.auth().currentUser

          this.order.ref
          .collection('messages')
          .add({
            created: firebase.firestore.Timestamp.now(),
            message: `Status changed to ${ startCase(status) }`,
            type: 'status_change',
            sender: user.uid,
            note: false,
            html: false,
          })
          .catch(error => {
            console.log(error.message)
          })

          if (status == 'completed') {
            let emailData = {
              to: this.$store.getters['users/user'](this.order.user).email,
              subject: 'Order Completed',
              message: `
                Hi ${this.$store.getters['users/user'](this.order.user).fullName},<br/>
                <br/>
                Congratulations! Your order has been completed.<br/>
                Please leave us your feedback <a href="${window.origin}${this.$router.resolve({ name: 'OrderDetails', params: { id: this.order.id } }).href}">here</a>.<br/>
                <br/>
                All The Best,<br/>
                Lightning Sites Team
              `
            }

            this.$store.dispatch('email/sendMail', emailData)
          }
      })
    },

    getColor(status) {
      if (status == 'pending_payment') return 'warning'
      else if (status == 'completed') return 'light-green'
      else if (status == 'escalated') return 'error'
      else if (['reviewing_with_client', 'checking_quality'].includes(status)) return 'purple'
      else return 'blue'
    }
  }
}
</script>