<template>
<div>
  <v-card 
    v-if="order && !order.project && !$store.state.projects.status.getting" 
    color="#fef9e8" 
    class="mb-5" 
    flat
  >
    <v-card-text>
      <div class="mb-5">
        You haven't selected a Lightning Builder project to show us what pages you want on your website.
      </div>

      <v-btn
        v-if="!$store.state.projects.projects.length"
        :to="{ name: 'Projects' }" 
        class="primary--text" 
        color="white" 
        small
      >
        Create A Project
      </v-btn>
      
      <v-btn
        v-else
        @click="showDialog()"
        class="primary--text" 
        color="white" 
        small
      >
        Select A Project
      </v-btn>
    </v-card-text>

    <select-project-dialog ref="selectDialog" @selected="selected" />
  </v-card>
</div>
</template>

<script>
import SelectProjectDialog from '../../project/components/SelectProjectDialog.vue'

export default {
  props: {
    order: Object,
  },
  
  components: { 
    SelectProjectDialog 
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    showDialog() {
      this.$refs.selectDialog.dialog = true
    },

    selected(project) {
      this.$store.dispatch('orders/updateOrderField', {
        order: this.order,
        value: project.id,
        field: 'project'
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.$store.state.projects.status.firstLoad) 
      this.$store.dispatch('projects/getProjects')
  }
}
</script>