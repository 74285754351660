<template>
  <v-dialog
    v-model="dialog"
    :max-width="$dialog.content"
  >
    <close-btn @click="closeDialog()" overflow />

    <v-card>
      <v-card-title>
        <h5>Select a brand profile</h5>
      </v-card-title>
      <v-card-text>
        <v-card class="box-shadow" outlined>
          <v-list v-if="confProfiles.length" dense>
            <v-list-item
              v-for="profile in confProfiles"
              :key="profile.id"
              @click="selected(profile)"
            >
              <v-list-item-title>
                {{ profile.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>

        <v-alert v-if="!confProfiles.length" type="info" border="left" text>
          You don't have any brand profile. Create one <router-link :to="{ name: 'BrandProfiles' }">here</router-link>.
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import firebase from 'firebase'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      dialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.brand_profile.status,
      profiles: state => state.brand_profile.profiles,
    }),

    confProfiles: function () {
      let user = firebase.auth().currentUser

      return this.profiles.filter(profile => {
        return profile.user == user.uid
      })
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    closeDialog() {
      this.dialog = false
    },

    selected(profile) {
      this.$emit('selected', profile)
      this.closeDialog()
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.status.firstLoad)
      this.$store.dispatch('brand_profile/getBrandProfiles')
  }
}
</script>