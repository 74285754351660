<template>
  <div v-if="order" class="d-flex align-center gap-10">
    <template v-if="order.assignee">
      <v-list-item 
        @click.prevent="$store.getters['orders/canManage'] ? showDialog() : ''"
        :style="{ minHeight: '30px !important' }"
        class="px-1"
        dense
      >
        <v-list-item-avatar class="overflow-visible mr-1 my-0" height="30" rounded>
          <UserPhoto :size="30" photoSize="thumb" :id="order.assignee" rounded />
        </v-list-item-avatar>
        <v-list-item-title :class="{ 'caption blue-grey--text' : small }">
          {{ $store.getters['users/employee'](order.assignee).fullName }}
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-else>
      <v-list-item 
        @click.prevent="$store.getters['orders/canManage'] ? showDialog() : ''"
        :style="{ minHeight: '30px !important' }"
        class="px-1 pl-0"
        dense
      >
        <v-list-item-avatar class="overflow-visible mr-1 my-0" height="30" rounded>
          <v-icon size="34" color="grey lighten-1">mdi-account-box-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-title class="caption grey--text">
          Not assigned
        </v-list-item-title>
      </v-list-item>
    </template>

    <!-- EMPLOYEE DIALOG -->
    <employee-dialog
      ref="employeeDialog" 
      @confirmed="confirmed"
      :default="order.assignee"
    />
  </div>
</template>

<script>
import EmployeeDialog from '../../users/components/EmployeeDialog.vue'
import { mapActions } from 'vuex'

export default {
  props: {
    order: Object,
    small: Boolean,
    form: Object,
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    EmployeeDialog
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('orderform', [
      'setAssignee'
    ]),
    
    ...mapActions('orders', [
      'updateOrderField'
    ]),

    showDialog() {
      this.$refs.employeeDialog.dialog = true
    },

    closeDialog() {
      this.$refs.employeeDialog.dialog = false
    },

    confirmed(employee) {
      this.$refs.employeeDialog.loading = true
      
      if (this.form) {
        Promise.resolve(
          this.setAssignee({
            form: this.form,
            id: employee
          })
        )
        .then(() => {
          this.closeDialog()
          this.$refs.employeeDialog.loading = false
        })
        .catch(error => {
          console.log(error.message)
          this.$store.dispatch('showError', error.message)
        })
      }
      else if (this.order) {
        Promise.resolve(
          this.updateOrderField({
            order: this.order,
            field: 'assignee',
            value: employee,
          })
        )
        .then(() => {
          this.closeDialog()
          this.$refs.employeeDialog.loading = false
        })
      }
    }
  }
}
</script>