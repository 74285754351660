<template>
  <div v-if="order">
    <div class="d-flex align-center gap-4">
      <template v-if="order && order.followers && order.followers.length">
        <v-menu
          v-for="(follower, index) in order.followers"
          :key="index"
          open-on-hover
          offset-y 
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <div 
              class="d-inline-block"
              v-bind="attrs"
              v-on="on"
            >
              <user-photo
                :id="follower"
                photoSize="thumb"
                :size="25"
                v-on="on"
                rounded
              />
            </div>
          </template>

          <v-card>
            <v-card-text class="pa-0">
              <v-list>
                <v-list-item>
                  <v-list-item-avatar class="overflow-visible" rounded>
                    <UserPhoto :size="40" photoSize="thumb" :id="follower" rounded />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="primary--color body-1">{{ $store.getters['users/user'](follower).fullName }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle class="caption">
                        <a :href="`mailto:${$store.getters['users/user'](follower).email}`" class="text-decoration-none">{{ $store.getters['users/user'](follower).email }}</a>
                        <div v-if="$store.getters['users/user'](follower).organization && $store.getters['users/user'](follower).employeeRole">{{ $store.getters['users/user'](follower).employeeRole }}</div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>  
          </v-card>
        </v-menu>
      </template>

      <v-btn
        @click="showDialog()"
        color="grey lighten-2"
        class="rounded-sm"
        height="25"
        width="25"
        outlined
        icon
      >
        <v-icon x-small>mdi-plus</v-icon>
      </v-btn>
    </div>

    <employee-dialog 
      ref="employeeDialog"
      @confirmed="confirmed"
      :default="order.followers"
      :exclude="[order.assignee]"
      multiple
    />
  </div>
</template>

<script>
import EmployeeDialog from '../../users/components/EmployeeDialog'

export default {
  props: {
    order: Object,
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    EmployeeDialog
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    showDialog() {
      this.$refs.employeeDialog.showDialog()
    },

    closeDialog() {
      this.$refs.employeeDialog.closeDialog()
    },

    confirmed(values) {
      this.$refs.employeeDialog.loading = true
      let prevValues = this.order.followers ? JSON.parse(JSON.stringify(this.order.followers)) : []

      Promise.resolve(
        this.$store.dispatch('orders/updateOrderField', {
          order: this.order,
          value: values,
          field: 'followers',
        })
      )
      .then(() => {
        this.$refs.employeeDialog.loading = false
        this.$refs.employeeDialog.selectedEmployees = []
        this.closeDialog()

        values.forEach(id => {
          if (!prevValues.includes(id))
            this.sendEmailNotification(id)
        })
      })
    },

    sendEmailNotification(id) {
      this.$store.dispatch('email/sendMail', {
        to: this.$store.getters['users/employee'](id).email,
        subject: 'You have been added as a follower',
        message: `
          Hi ${this.$store.getters['users/employee'](id).fullName},<br/><br/>
          You have been added as a follower.<br/>
          Click <a href="${window.origin}/orders/${this.order.id}">here</a> for more details.
        `
      })
    }
  }
}
</script>