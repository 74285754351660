<template>
  <v-card v-if="order" outlined>
    <v-card-text v-if="order.status == 'pending_payment'">
      <v-alert
        v-if="order.status == 'pending_payment'"
        border="left"
        type="info"
        text
      >
        Complete your order and start uploading your files.

        <v-btn
          @click="$store.dispatch('products/payOnce', order)" 
          :loading="$store.state.products.status.paying"
          class="primary--text"
          color="white" 
          absolute
          right
          small
        >
          Complete Order
        </v-btn>
      </v-alert>
    </v-card-text>
    <v-card-text v-else class="pa-10">
      <v-sheet width="100%">
        <preloader v-if="getting" />

        <v-alert class="mb-0" type="info" v-if="!getting && !files.length" border="left" text>
          No files found
        </v-alert>
      </v-sheet>
      
      <template v-if="!getting && files.length">
        <v-fade-transition class="fill-width d-flex gap-7 flex-wrap" group>
          <file-block
            v-for="file in files"
            :key="file.id"
            :file="file"
            @deleted="fileDeleted(file)"
          />    
        </v-fade-transition>
      </template>

      <div class="mt-7">
        <upload-progress />
        
        <div class="d-flex align-center gap-7 full-width">
          <v-file-input
            v-model="attachments"
            class="flex-grow-1"
            hide-details
            multiple
            outlined
            dense
          ></v-file-input>
          
          <v-btn 
            @click="uploadFiles()"
            :disabled="!attachments"
            class="primary--text"
            color="white" 
            small
          >
            Upload
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import db from '@/firebase/init'
import FileBlock from './FileBlock'

export default {
  props: {
    order: Object,
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  data() {
    return {
      files: [],
      getting: false,
      attachments: null,
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'order': {
      handler(order) {
        if (Object.keys(order).length) this.getAttachments(order)
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    FileBlock,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    /*------------------------------------------------------------------------------
     * GET ATTACHMENTS
     *----------------------------------------------------------------------------*/
    getAttachments(order) {
      order.ref
      .collection('attachments')
      .orderBy('created', 'desc')
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let file = doc.data()
            file.ref = doc.ref
            file.id = doc.id
            if (!this.files.find(f => f.id == file.id))
              this.files.push(file)
          })
        }
      })
    },

    /*------------------------------------------------------------------------------
     * UPLOAD FILES
     *----------------------------------------------------------------------------*/
    uploadFiles() {
      let promises = []
      let batch = db.batch()

      this.attachments.forEach(attachment => {
        promises.push(
          this.$store.dispatch('file_upload/uploadFile', {
            file: attachment,
            folder: 'orders'
          })
        )
      })

      Promise.all(promises)
      .then((files) => {
        let newFiles = []

        files.forEach(file => {
          let fileRef = this.order.ref.collection('attachments').doc()
          file.deletable = true
          batch.set(fileRef, file)
          file.ref = fileRef
          file.id = fileRef.id
          newFiles.push(file)
        })

        batch.commit()
        .then(() => {
          this.files = this.files.concat(newFiles)
          this.attachments = null
          this.$store.dispatch('showSuccess', 'Files uploaded')
        })
      })
    },

    /*------------------------------------------------------------------------------
     * FILE DELETED
     *----------------------------------------------------------------------------*/
    fileDeleted(file) {
      file.ref.delete()
      .then(() => {
        let deleted = this.files.find(f => f.id == file.id)
        this.files.splice(this.files.indexOf(deleted), 1)
      })
      .catch(error => {
        console.log(error.message)
        this.$store.dispatch('showError', error.message)
      })
    },
  }
}
</script>