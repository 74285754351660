<template>
  <div>
    <v-menu v-if="clientId" open-on-hover offset-y>
    <template #activator="{ on }">
      <div v-on="on">
        <span>
          {{ client.fullName }}
        </span>
      </div>
    </template>
    <v-list dense>
      <v-list-item>
        <v-list-item-avatar class="overflow-visible mr-2" rounded>
          <UserPhoto
            :size="40"
            photoSize="thumb"
            :id="clientId"
            rounded
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle>
            <a
              :href="`mailto:${client.email}`"
              class="text-decoration-none d-block mb-2"
            >
              {{ client.email}}
            </a>
            <template v-if="client.requestPackages && client.requestPackages.length">
              <v-chip
                v-for="pkg in client.requestPackages"
                class="ml-1 grey--text"
                :key="pkg.id"
                outlined
                x-small
                label
              >
                {{ $store.getters["request/rPackage"](pkg).name }}
              </v-chip>
            </template>
            <v-chip
              v-else-if="$store.getters['request/rPackage'](client.requestPackage)"
              class="ml-1 grey--text"
              outlined
              x-small
              label
            >
              {{ $store.getters["request/rPackage"](client.requestPackage).name }}
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <template v-if="companyProfile">
        <v-list-item>
          <v-list-item-title class="text--disabled overline">Company Profile</v-list-item-title>
        </v-list-item>
        <v-list-item @click="profileDialog = true">
          <v-list-item-title class="text--secondary">
            {{ companyProfile.name }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>

  <!-- BRAND PROFILE -->
    <v-dialog :max-width="$dialog.medium" v-model="profileDialog" scrollable>
      <close-btn @click="profileDialog = false" overflow />

      <v-card>
        <v-card-text class="pt-7">
          <BrandProfile
            v-if="companyProfile"
            :id="companyProfile.id"
            readonly
            noHeader
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BrandProfile from '@/views/brand_profiles/EditBrandProfile'

export default {
  data() {
    return {
      profileDialog: false,
    }
  },

  components: {
    BrandProfile
  },

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    clientId: {
      type: String,
      required: true,
    },
    companyProfile: Object,
  },

  computed: {
    client: function () {
      return this.$store.getters["users/user"](this.clientId)
    }
  }
}

</script>