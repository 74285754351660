<template>
  <v-hover v-slot="{ hover }">
    <v-card
      :class="{ 'box-shadow': hover }"
      :style="{ transition: 'all 0.3s' }"
      height="180"
      width="200"
      outlined
    >
      <v-card-text class="d-flex flex-wrap align-content-center justify-center fill-height">
        <v-icon :color="$fileIcon(file.type, true)" large>{{ $fileIcon(file.type) }}</v-icon> 
        <div class="text-truncate d-block">{{ file.file | fileName }}</div>
        <div class="caption grey--text text--lighten-2">
          [{{ file.size | fileSize }}]
        </div>
        <div class="caption grey--text text--lighten-2">
            Uploaded: {{ file.created | formatDate }}
        </div>
      </v-card-text>

      <v-menu offset-y left>
        <template #activator="{ on }">
          <v-btn
            :style="{ right: '-1px', bottom: '-1px', height: '25px' }"
            class="rounded-lg rounded-tr-0 rounded-bl-0"
            color="grey lighten-3"
            v-on="on"
            outlined 
            absolute
            small
            fab
          >
            <v-hover>
              <v-hover v-slot="{ hover }">
                <v-icon :color="hover ? 'primary' : 'grey lighten-2'" large>mdi-dots-horizontal</v-icon>
              </v-hover>
            </v-hover>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click.prevent="$downloadFile('orders', file.file)">
            <v-list-item-title>Download</v-list-item-title>
          </v-list-item>
          <template v-if="file.deletable">
            <v-divider></v-divider>
            <v-list-item @click="showDeleteDialog()">
              <v-list-item-title class="error--text">Delete</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      
      <confirm-delete
        :show="deleteDialog"
        :message="`Delete file ${file.file}?`"
        :deleting="deleting"
        @cancel="closeDialog()"
        @confirmed="deleteConfirmed()"
      />
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: {
    file: Object
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      deleting: false,
      deleteDialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    showDeleteDialog() {
      this.deleteDialog = true
    },

    closeDialog() {
      this.deleteDialog = false
    },

    deleteConfirmed() {
      this.deleting = true
      
      Promise.resolve(
        this.$store.dispatch('file_upload/deleteFile', {
          folder: 'orders',
          file: this.file.file
        })
      )
      .then(() => {
        this.closeDialog()
        this.$emit('deleted')
      })
    }
  }
}
</script>